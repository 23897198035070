<template>
  <div id="orderPending">
    <div class="goBack">
      <i class="goBackClose" @click="jumpBack"></i>
    </div>
    <div class="pendingTitle">订单挂起</div>
    <div class="pendingReason">
      <div class="reasonTitle">请选择订单挂起原因<span>*</span></div>
      <div class="reasonChoose" @click="showReasonPopup">
        {{ reason }}<i></i>
      </div>
    </div>
    <van-popup v-model="pendingReasonShow" round position="bottom">
      <van-picker
        title="挂起原因"
        show-toolbar
        value-key="content"
        :columns="columnsList"
        @confirm="onConfirm"
        @cancel="pendingReasonShow = false"
      />
    </van-popup>
    <div class="reasonInstructions">
      <div class="reasonTitle">挂起原因说明<span>*</span></div>
      <textarea
        v-model="inputInstructions"
        class="inputInstructions"
        maxlength="100"
        placeholder="请输入挂起原因，最多100字"
      ></textarea>
    </div>
    <div class="uploadPictures">
      <div class="uploadTitle">上传图片</div>
      <div class="uploadBox">
        <van-uploader
          v-model="fileList"
          :after-read="afterRead"
          multiple
          preview-image
          preview-full-image
          :before-delete="imgDelete"
          :max-count="10"
        />
      </div>
    </div>
    <div class="hangTime" id="hangTime">
      <div class="chooseReason">请选择自动解挂时间(天)<span>*</span></div>
      <van-radio-group v-model="radio" icon-size="16px">
        <van-radio name="0">手动解挂</van-radio>
        <van-radio name="1" disabled>选择解挂时间</van-radio>
      </van-radio-group>
      <div class="numberDays" @click="showHangPopup" v-if="radio == 2">
        {{ numberDays }}<i></i>
      </div>
    </div>
    <div class="sweetTip">
      <div class="title">说明:</div>
      <p>
        1.
        订单挂起期间，订单进程和工程师的保险将暂停执行，工程师的服务质量指标也不受影响；
      </p>
      <p>2. 用户只能解挂自已操作的挂起订单；</p>
      <!-- <p>3. 订单挂起默认会自动解挂，请选择合适的解挂时长。</p> -->
    </div>
    <van-popup v-model="hangTimeShow" round position="bottom">
      <van-picker
        title="解挂时间"
        show-toolbar
        :columns="dayList"
        @confirm="onConfirmDay"
        @cancel="hangTimeShow = false"
      />
    </van-popup>
    <div class="pendingConfirm" @click="confirmUp">确认挂起</div>
    <van-overlay :show="show">
      <van-loading size="24px" vertical>上传中...</van-loading>
    </van-overlay>
  </div>
</template>

<script>
import {
  popup,
  picker,
  uploader,
  dialog,
  RadioGroup,
  Radio,
} from "../utils/vant";
import {
  queryHangConfigList,
  feedbackOrderHangEx,
  getJsConfig,
} from "@/api/home";
import { afterReadPubile, imgDeletePubile } from "../utils/upload";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      userId: this.$route.query.userId, //用户id
      orderNo: this.$route.query.orderNo, //订单id
      inputInstructions: "", //挂起原因输入
      numberDays: 10, //解挂时间默认显示
      reason: "", //挂起原因默认显示
      reasonId: "", //挂起原因分类id
      hangTimeShow: false, //解挂弹窗显示
      pendingReasonShow: false, //挂起原因显示
      dayList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      columnsList: [],
      fileList: [],
      exImgs: [],
      imgList: [],
      show: false, //上传加载显示
      radio: "0",
    };
  },
  mounted() {
    this.getQueryHangConfigList();
    // this.WXconfig();
  },
  methods: {
    // WXconfig() {
    //   getJsConfig({ url: window.location.href }).then((res) => {
    //     if (res.status) {
    //       let data = res.resultMap.jsTicket;
    //       wx.config({
    //         debug: false,
    //         appId: res.resultMap.appId,
    //         nonceStr: data.noncestr,
    //         signature: data.signature,
    //         timestamp: data.timestamp,
    //         jsApiList: ["scanQRCode", "chooseImage", "uploadImage"],
    //       });
    //       wx.ready(() => {
    //         console.log("wx ready");
    //       });
    //       wx.error(function (res) {
    //         console.log("出错了：" + res.errMsg); //这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
    //       });
    //     }
    //   });
    // },
    // up() {
    //   wx.chooseImage({
    //     count: 9, // 默认9
    //     sizeType: ["original", "compressed"], // 可以指定是原图还是压缩图，默认二者都有
    //     sourceType: ["album", "camera"], // 可以指定来源是相册还是相机，默认二者都有
    //     success: function (res) {
    //       let localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
    //       localIds.forEach((li) => {
    //         this.imgList.push(li);
    //       });
    //       console.log(this.imgList);
    //       // wx.uploadImage({
    //       //   localId: localIds, // 需要上传的图片的本地ID，由chooseImage接口获得
    //       //   isShowProgressTips: 1, // 默认为1，显示进度提示
    //       //   success: function (res) {
    //       //     let serverId = res.serverId; // 返回图片的服务器端ID
    //       //   }
    //       // });
    //     },
    //   });
    // },
    //查询订单挂起原因列表
    getQueryHangConfigList() {
      let userId = this.userId;
      queryHangConfigList(userId).then((res) => {
        if (res.status) {
          this.columnsList = res.result;
          this.reason = res.result[0].content;
          this.reasonId = res.result[0].trackConfigId;
        }
      });
    },
    // 跳转返回
    jumpBack() {
      window.history.go(-1);
    },
    // 显示挂起原因弹窗
    showReasonPopup() {
      this.pendingReasonShow = true;
    },
    // 挂起原因确认
    onConfirm(val) {
      this.reason = val.content;
      this.reasonId = val.trackConfigId;
      this.pendingReasonShow = false;
    },
    // 显示解挂弹窗
    showHangPopup() {
      this.hangTimeShow = true;
    },
    // 解挂时间确认
    onConfirmDay(val) {
      this.numberDays = val;
      this.hangTimeShow = false;
    },
    afterRead(file) {
      afterReadPubile(file, this.exImgs);
    },
    imgDelete(file, detail) {
      imgDeletePubile(detail, this.fileList, this.exImgs);
    },
    //确认提交挂起数据
    confirmUp() {
      let _this = this;
      if (_this.inputInstructions == "") {
        _this.$dialog.alert({
          message: "挂起原因不能为空！",
        });
        return;
      }
      this.show = true;
      let params = {
        userId: _this.userId,
        classId: _this.reasonId,
        className: _this.reason,
        //exAutoHandel: _this.numberDays,
        orderNo: _this.orderNo,
        exDesc: _this.inputInstructions,
        exImgs: _this.exImgs + "",
        handelType: _this.radio,
      };

      feedbackOrderHangEx(params).then((res) => {
        if (res.status) {
          this.show = false;
          _this.$dialog
            .alert({
              //message: `订单挂起成功！\n 订单将于${_this.numberDays}天自动解挂`,
              message: `${res.message}`,
            })
            .then(() => {
              window.history.go(-1);
            });
        } else {
          this.show = false;
          _this.$dialog.alert({
            message: "订单挂起异常，请稍后再试！",
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
#orderPending {
  text-align: left;

  .goBack {
    width: 750px;
    height: 76px;
    background: #ffffff;

    .goBackClose {
      display: inline-block;
      width: 31px;
      height: 31px;
      margin: 23px 0 0 25px;
      background: url("../assets/images/pending/goBackClose.png") no-repeat;
      background-size: 100%;
      cursor: pointer;
    }
  }

  .pendingTitle {
    font-size: 54px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #111111;
    line-height: 54px;
    margin: 25px 0 0 29px;
  }

  .pendingReason {
    margin: 79px 0 0 29px;

    .reasonTitle {
      height: 24px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #111111;
      line-height: 30px;

      span {
        font-size: 26px;
        color: #ff7000;
        margin-left: 4px;
        vertical-align: middle;
      }
    }

    .reasonChoose {
      position: relative;
      width: 690px;
      height: 80px;
      background: #ffffff;
      border: 1px solid #dadada;
      border-radius: 7px;
      margin-top: 30px;
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #111111;
      line-height: 80px;
      text-indent: 21px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      i {
        position: absolute;
        width: 20px;
        height: 11px;
        top: 35px;
        right: 21px;
        background: url("../assets/images/pending/downArrow.png") no-repeat;
        background-size: 100%;
      }
    }
  }

  .reasonInstructions {
    margin: 50px 0 0 29px;

    .reasonTitle {
      height: 24px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #111111;
      line-height: 30px;

      span {
        font-size: 26px;
        color: #ff7000;
        margin-left: 4px;
        vertical-align: middle;
      }
    }

    .inputInstructions {
      width: 690px;
      height: 248px;
      padding: 20px;
      background: #ffffff;
      border: 1px solid #dadada;
      border-radius: 7px;
      margin-top: 30px;
      resize: none;
      outline: none;
      box-sizing: border-box;
      font-size: 26px;
    }
  }

  .uploadPictures {
    margin: 49px 0 0 29px;

    .uploadTitle {
      height: 24px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #111111;
      line-height: 30px;
      padding: 20px 0;
    }

    .uploadBox {
      width: 690px;
      background: #ffffff;
      border: 1px solid #dadada;
      border-radius: 7px;
      margin-top: 31px;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 110px;
          height: 110px;
          margin: 10px 14px;
        }
        .uploadImg {
          position: relative;
          .showImg {
            width: 100%;
            height: 100%;
          }
          .deleteImg {
            position: absolute;
            top: -10px;
            right: -10px;
            width: 32px;
            height: 32px;
          }
        }
        .addButton {
          background: #f5f5f5;
          display: flex;
          img {
            margin: auto;
            width: 37px;
            height: 59px;
          }
        }
      }
    }
  }

  .hangTime {
    margin: 49px 0 0 29px;

    /deep/.van-radio-group {
      margin-top: 30px;
    }

    /deep/.van-radio {
      margin-top: 20px;
    }

    /deep/.van-radio__label {
      font-size: 28px;
      line-height: 28px;
      color: #000;
    }

    .chooseReason {
      height: 24px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #111111;
      line-height: 30px;

      span {
        font-size: 26px;
        color: #ff7000;
        margin-left: 4px;
        vertical-align: middle;
      }
    }

    .numberDays {
      position: relative;
      width: 690px;
      height: 80px;
      background: #ffffff;
      border: 1px solid #dadada;
      border-radius: 7px;
      margin-top: 30px;
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #111111;
      line-height: 80px;
      text-indent: 21px;
      cursor: pointer;

      i {
        position: absolute;
        width: 20px;
        height: 11px;
        top: 35px;
        right: 21px;
        background: url("../assets/images/pending/downArrow.png") no-repeat;
        background-size: 100%;
      }
    }
  }

  .sweetTip {
    margin: 49px 0 0 29px;
    color: #999999;

    .title {
      font-size: 26px;
    }

    p {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 36px;
    }
  }

  .pendingConfirm {
    width: 690px;
    height: 80px;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #fafafa;
    text-align: center;
    line-height: 80px;
    background: #ff7000;
    border-radius: 8px;
    margin: 60px auto;
    cursor: pointer;
  }
}
.uploadBox /deep/ .van-uploader__preview {
  margin: 20px 0 0 20px;
}

.uploadBox /deep/ .van-uploader__preview:nth-child(n + 6) {
  margin-bottom: 20px;
}

.uploadBox /deep/ .van-uploader__preview.class:nth-child(n + 6) {
  margin-bottom: 20px;
}

.uploadBox /deep/ .van-uploader__upload {
  margin: 20px;
  width: 110px;
  height: 110px;
}

.uploadBox /deep/ .van-uploader__upload-icon {
  width: 37px;
  height: 59px;
  background: url("../assets/images/proof/addImage.png") no-repeat;
  background-size: 100%;
}

.uploadBox /deep/ .van-icon-photograph::before {
  content: "";
}

.uploadBox /deep/ .van-uploader__preview-image {
  width: 110px;
  height: 110px;
}
</style>
